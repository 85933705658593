import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

export default function Footer() {
  const useStyles = makeStyles(theme => ({
    footer: {
      backgroundColor: theme.palette.background.paper
    }
  }));

  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#322F5E" d="M0,32L20,64C40,96,80,160,120,202.7C160,245,200,267,240,240C280,213,320,139,360,138.7C400,139,440,213,480,229.3C520,245,560,203,600,165.3C640,128,680,96,720,80C760,64,800,64,840,80C880,96,920,128,960,144C1000,160,1040,160,1080,181.3C1120,203,1160,245,1200,229.3C1240,213,1280,139,1320,101.3C1360,64,1400,64,1420,64L1440,64L1440,320L1420,320C1400,320,1360,320,1320,320C1280,320,1240,320,1200,320C1160,320,1120,320,1080,320C1040,320,1000,320,960,320C920,320,880,320,840,320C800,320,760,320,720,320C680,320,640,320,600,320C560,320,520,320,480,320C440,320,400,320,360,320C320,320,280,320,240,320C200,320,160,320,120,320C80,320,40,320,20,320L0,320Z"></path></svg>
      <Typography variant="h6" align="center" gutterBottom>
        Keyring Planet &copy; {new Date().getFullYear()}
      </Typography>
    </footer>
  );
}
